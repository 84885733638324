import browserUpdate from 'browser-update/update.npm';
import "focus-visible/dist/focus-visible";
import svg4everybody from 'svg4everybody';
import objectFitImages from 'object-fit-images';

// See : https://github.com/browser-update/browser-update/wiki/Details-on-configuration
browserUpdate({
  required: {
    e: 16, // Edge
    i: 11, // IE
    f: -2, // Firefox
    s: -2, // Safari
    c: -2, // Chrome
  },
  insecure: true,
});

// See : https://github.com/jonathantneal/svg4everybody
svg4everybody();

objectFitImages('[data-object-fit]');
